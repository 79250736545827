import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { PhonecodeDropdown } from "@app/components/common/form/phone-input/components/phonecode-dropdown/PhonecodeDropdown";
import { ModalState } from "@app/enums/modal";
import { SMSLinkFormData } from "@app/types/forms";
import { useModal } from "@app/hooks";
import { RECAPTCHA_ACTION } from "../../constants";
import { useSMS } from "./useSMS";
import { useValidationSchema } from "./useValidationSchema";
import { PhoneInput } from "./components/phone-input/PhoneInput";

export const GetLinkForm: FC = () => {
  const { t } = useTranslation("download-app-modal");
  const validationSchema = useValidationSchema(t);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phoneCode: {
        alphaCode: "CH",
        code: "41",
      },
    },
  });
  const { sendSMSRequest } = useSMS();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { onChangeModalState } = useModal();

  const onSubmit = async (data: SMSLinkFormData) => {
    try {
      const recaptchaToken = await executeRecaptcha(RECAPTCHA_ACTION);

      await sendSMSRequest(
        `${data.phoneCode.code}${data.phone}`,
        t("sms"),
        recaptchaToken,
        RECAPTCHA_ACTION,
      );
      methods.reset();
      onChangeModalState(ModalState.SMS_SUCCESS);
    } catch (error) {
      onChangeModalState(ModalState.ERROR);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex w-full items-center gap-2.5 text-small text-neutral-content">
          <PhonecodeDropdown
            name="phoneCode"
            dropdownBgVariant="grey"
            vAlign="top"
          />
          <PhoneInput name="phone" placeholder={t("phone_placeholder")} />
        </div>
      </form>
    </FormProvider>
  );
};
