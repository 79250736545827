import React from "react";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import { LinkButton } from "@app/components/common/link-button/LinkButton";
import { ButtonVariant, LinkButtonType } from "@app/components/common/enums";
import { useDownloadAppTopbar } from "@app/hooks";

interface Props {
  getAppLabel: string;
}

export const DownloadAppBar = ({ getAppLabel }: Props) => {
  const { isVisible, onHide } = useDownloadAppTopbar();

  return (
    <div
      className={classNames(
        "flex items-center justify-between bg-base-100 py-3 pl-4 pr-5 duration-300",
        "md:-mt-[72px]",
        { "-mt-[72px]": !isVisible },
      )}
    >
      <div className="flex items-center">
        <div onClick={onHide}>
          <StaticImage
            className={"mr-3 h-6 w-6"}
            src="./xmark_icon.png"
            alt="Close notification"
            quality={100}
          />
        </div>
        <StaticImage
          className={"mr-6 h-12 w-12"}
          src="./app_icon.png"
          alt="Alpian App"
          quality={100}
        />
        <div>
          <p className="mb-1.5 text-extra-small-low text-primary-content">
            Alpian
          </p>
          <div className="align-center flex gap-x-1.5">
            <StaticImage
              className={"max-w-20"}
              src="./star_scores.png"
              alt="App rating"
              quality={100}
            />
            <div className="text-tiny text-neutral-content">4.7</div>
          </div>
        </div>
      </div>
      <div>
        <LinkButton
          label={getAppLabel}
          to="#"
          variant={ButtonVariant.FILL_INFO}
          type={LinkButtonType.DOWNLOAD_APP}
        />
      </div>
    </div>
  );
};
