export enum Dialog {
  COOKIES_SETTINGS = "cookies-settings",
  FINANCIAL_REPORT_SUCCESS = "financial-report-success",
  LOST_CARD_SUCCESS = "lost-card-success",
  SMS_SUCCESS = "sms-success",
  SUBSCIBE = "subscribe",
  SUBSCRIBE_SUCCESS = "subscribe-sucess",
  SUCCESS = "success",
  ERROR = "error",
  DOWNLOAD_APP = "download-app",
  SCHEDULE_A_CALL = "schedule-a-call",
  SCHEDULE_A_CALL_SUCCESS = "schedule-a-call-success",
}
