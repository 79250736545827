module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d5d3d236cafda611be80fdfc2268f3d7"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"quality":80,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr","de","it"],"defaultLanguage":"en","siteUrl":"https://www.alpian.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/perks","getLanguageFromPath":false},{"matchPath":"/:lang?/perk/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/bank/apple-pay","getLanguageFromPath":false},{"matchPath":"/:lang?/alpian-ambassadors","getLanguageFromPath":false},{"matchPath":"/:lang?/get-app","getLanguageFromPath":false},{"matchPath":"/:lang?/events","getLanguageFromPath":false},{"matchPath":"/:lang?/contact","getLanguageFromPath":false},{"matchPath":"/:lang?/404","getLanguageFromPath":false},{"matchPath":"/:lang?/lost-card","getLanguageFromPath":false},{"matchPath":"/:lang?/complaint","getLanguageFromPath":false},{"matchPath":"/:lang?/contact/ombudsman","getLanguageFromPath":false},{"matchPath":"/:lang?/investment/cost-calculator","getLanguageFromPath":false},{"matchPath":"/:lang?/financial-reports","getLanguageFromPath":false},{"matchPath":"/:lang?/news","getLanguageFromPath":false},{"matchPath":"/:lang?/legal","getLanguageFromPath":false},{"matchPath":"/:lang?/news/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/faq","getLanguageFromPath":true},{"matchPath":"/:lang?/event/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/legal/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/lp/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/:uid?","getLanguageFromPath":true},{"matchPath":"/:lang?/bank/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/bank/currency-converter/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/investment/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/@tmttn/gatsby-plugin-hubspot-tracking/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"26740162","respectDNT":false,"productionOnly":true,"region":"eu1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
